import { storyblokEditable } from '@storyblok/react/rsc'
import type { SectionHeaderStoryblok } from '@vendure/codegen/storyblok'
import {
  PreTitle,
  RichText,
  TitleWithAccent,
} from '@vendure/components/shared/client'
import { cn } from '@vendure/helpers'
import { useMemo } from 'react'

interface ISectionHeader {
  blok: SectionHeaderStoryblok
  containerBackgroundColor?: string
}

export function SectionHeader({ blok }: ISectionHeader) {
  const hasButtons = (blok.actionButtonsRight?.length ?? 0) > 0

  const titleAndText = useMemo(() => {
    return (
      <div className="max-w-full md:max-w-screen-md">
        {blok.preTitle && (
          <PreTitle
            containerProps={{
              className: 'mb-4',
            }}
            tag={blok.preTitleTag as any}
            button={hasButtons ? blok.actionButtonsRight?.at(0) : undefined}
          >
            {blok.preTitle}
          </PreTitle>
        )}
        <TitleWithAccent
          containerProps={{
            className: 'mb-6 section-heading',
          }}
          tag={blok.titleTag}
          title={blok.title}
        />
      </div>
    )
  }, [blok])

  const title = useMemo(
    () => (
      <div
        className={cn(['flex', 'justify-start', 'items-start md:items-center'])}
      >
        {titleAndText}
      </div>
    ),
    [titleAndText],
  )

  return (
    <div
      className="mb-8 w-full"
      {...storyblokEditable(blok)}
    >
      {title}
      {blok.text && <RichText document={blok.text} />}
    </div>
  )
}
