import type { TestimonialStoryblok } from '@vendure/codegen/storyblok'
import { CustomImage, GradientCard } from '@vendure/components/shared/client'
import { getPrimaryColorFilter } from '@vendure/helpers'
import classNames from 'classnames'
import { HTMLProps } from 'react'

interface ITestimonial {
  blok: TestimonialStoryblok
  containerProps?: HTMLProps<HTMLDivElement>
}

export function Testimonial({ blok, containerProps }: ITestimonial) {
  return (
    <>
      {blok.displayMode === 'regular' ? (
        <RegularTestimonial
          blok={blok}
          containerProps={containerProps}
        />
      ) : (
        <CardTestimonial
          blok={blok}
          containerProps={containerProps}
        />
      )}
    </>
  )
}

export const CardTestimonial = ({ blok, containerProps }: ITestimonial) => {
  return (
    <GradientCard
      containerProps={{
        className: classNames(['p-9 md:p-5 xl:p-8 bg-slate-900']),
      }}
      outerProps={{
        className: containerProps?.className,
      }}
      {...containerProps}
    >
      <div className="flex flex-col justify-between gap-9">
        <div className="leading-relaxed">"{blok.text}"</div>
        <div className="flex items-center gap-5">
          {blok.image?.filename && (
            <GradientCard
              outerProps={{
                className: 'rounded-full',
              }}
            >
              <CustomImage
                width={100}
                height={100}
                className="h-12 w-12 object-cover object-center"
                alt={blok.name ?? ''}
                src={blok.image.filename}
              />
            </GradientCard>
          )}
          <div className="flex flex-col">
            <span className="text-md block font-semibold text-white">
              {blok.name}
            </span>
            <span className="text-sm font-semibold text-white/40">
              {blok.role}, {blok.company}
            </span>
          </div>
        </div>
      </div>
    </GradientCard>
  )
}

export const RegularTestimonial = ({ blok }: ITestimonial) => {
  return (
    <div className="flex items-center gap-8 text-center">
      {blok.logo?.filename && (
        <CustomImage
          width={300}
          height={150}
          className="h-[50px] w-auto"
          style={{
            filter: getPrimaryColorFilter(),
          }}
          src={blok.logo.filename}
          alt={blok.logo.alt ?? blok.company ?? ''}
        />
      )}
      <div className="md-max-w-[70%] mx-auto text-2xl md:text-3xl">
        <span>&quot;</span>
        {blok.text}
        <span>&quot;</span>
      </div>
      <div className="flex items-center gap-4">
        {blok.image?.filename && (
          <CustomImage
            src={blok.image.filename}
            className="h-12 w-12 overflow-hidden rounded-full object-cover object-center"
            width={100}
            height={100}
            alt={blok.name ?? ''}
          />
        )}
        <div className="flex items-center gap-1">
          <span className="text-lg font-semibold">{blok.name}</span>
          <span className="text-white/60">
            {blok.role}, {blok.company}
          </span>
        </div>
      </div>
    </div>
  )
}
